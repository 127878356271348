import React, { useState } from "react";
import api from "../api"; // Import the configured Axios instance

const LeadsList = ({
    fetchLeads,
    leads,
    setMessage,
    setNewLead,
    showLeadsForPropertyID,
}) => {
    const [showEditLeadForm, setShowEditLeadForm] = useState(null);

    const [editLead, setEditLead] = useState({
        name: "",
        email: "",
        notes: "",
    });

    const handleDeleteLead = async (id) => {
        try {
            await api.delete(`/leads/${id}`);
            fetchLeads();
            setMessage({
                text: "Lead deleted successfully!",
                type: "success",
            });
            setNewLead({
                name: "",
                email: "",
                notes: "",
            });
        } catch (error) {
            setMessage({ text: "Failed to delete lead.", type: "error" });
        }
    };

    const handleEditLead = async (id) => {
        try {
            await api.put(`/leads/${id}`, {
                name: editLead.name,
                email: editLead.email,
                notes: editLead.notes,
                property_id: showLeadsForPropertyID,
            });

            setShowEditLeadForm(false);
            fetchLeads(showLeadsForPropertyID);
            setEditLead({
                name: "",
                email: "",
                notes: "",
            });
            setMessage({
                text: "Lead updated successfully!",
                type: "success",
            });
        } catch (error) {
            setMessage({ text: "Failed to update lead.", type: "error" });
        }
    };

    return (
        <>
            {leads?.length === 0 ? (
                <p>No leads available. Please add some.</p>
            ) : (
                leads?.map((lead) => (
                    <div key={lead.id} className="property">
                        <div className="propertyWrapper">
                            <div className="dataInput">
                                {showEditLeadForm === lead.id ? (
                                    <div className="editForm">
                                        <div className="formInput">
                                            <label>Name:</label>
                                            <input
                                                type="text"
                                                value={editLead.name}
                                                onChange={(e) =>
                                                    setEditLead({
                                                        ...editLead,
                                                        name: e.target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                        <div className="formInput">
                                            <label>Email:</label>
                                            <input
                                                type="text"
                                                value={editLead.email}
                                                onChange={(e) =>
                                                    setEditLead({
                                                        ...editLead,
                                                        email: e.target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                        <div className="formInput">
                                            <label>Notes:</label>
                                            <textarea
                                                type="text"
                                                value={editLead.notes}
                                                onChange={(e) =>
                                                    setEditLead({
                                                        ...editLead,
                                                        notes: e.target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                        <button
                                            onClick={() =>
                                                handleEditLead(lead.id)
                                            }
                                        >
                                            Save
                                        </button>
                                        <button
                                            onClick={() => {
                                                setShowEditLeadForm(null);

                                                setNewLead({
                                                    name: "",
                                                    email: "",
                                                    notes: "",
                                                });
                                            }}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                ) : (
                                    <>
                                        <h3>{lead.name}</h3>
                                        <p>Email: {lead.email}</p>
                                        <p>Notes: {lead.notes}</p>
                                    </>
                                )}
                            </div>
                            <div className="actionButtons">
                                <button
                                    onClick={() => handleDeleteLead(lead.id)}
                                >
                                    Delete
                                </button>
                                <button
                                    onClick={() => {
                                        setShowEditLeadForm(lead.id);
                                        setEditLead({
                                            name: lead.name,
                                            email: lead.email,
                                            notes: lead.notes,
                                        });
                                    }}
                                >
                                    Edit
                                </button>
                            </div>
                        </div>
                    </div>
                ))
            )}
        </>
    );
};

export default LeadsList;
