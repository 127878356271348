// File: src/App.js
import React, { useState, useEffect } from "react";
import Login from "./components/Login";
import Register from "./components/Register";
import Properties from "./components/Properties";

function App() {
  const [token, setToken] = useState("");
  const [username, setUsername] = useState(""); // State to hold the username
  const [showLogin, setShowLogin] = useState(true); // Toggle state for showing login or register
  const [message, setMessage] = useState({ text: "", type: "" }); // Shared message state
  const [userId, setUserId] = useState(null); // Adding userId state

  const toggleForms = () => {
    setShowLogin(!showLogin);
    // Optionally clear message when toggling forms
    // setMessage({ text: '', type: '' });
  };

  useEffect(() => {
    localStorage.setItem("token", token);
    localStorage.setItem("username", username);
    localStorage.setItem("userId", userId);
  }, [token, username, userId]);

  const logout = () => {
    setToken("");
    setUsername("");
    setUserId("");
    localStorage.removeItem("token");
    localStorage.removeItem("username");
    localStorage.removeItem("userId");
  };

  return (
    <div className="container">
      {!token ? (
        showLogin ? (
          <Login
            setToken={setToken}
            setUsername={setUsername}
            toggleForms={toggleForms}
            setMessage={setMessage}
            message={message}
            setUserId={setUserId}
          />
        ) : (
          <Register
            toggleForms={toggleForms}
            setMessage={setMessage}
            message={message}
          />
        )
      ) : (
        <Properties
          token={token}
          setToken={setToken}
          username={username}
          userId={userId}
        />
      )}
    </div>
  );
}

export default App;
