// File: src/components/Properties.js
import React, { useState, useEffect } from "react";
import api from "../api"; // Import the configured Axios instance
import PropertiesList from "./PropertiesList";
import AddProperty from "./AddProperty";
import AddLead from "./AddLead";
import LeadsList from "./LeadsList";

const Properties = ({ token, setToken, username, userId }) => {
    const [showAddForm, setShowAddForm] = useState(false);
    const [showAddLeadForm, setShowAddLeadForm] = useState(false);
    const [showLeadsForPropertyID, setShowLeadsForPropertyID] = useState(null);
    const [newProperty, setNewProperty] = useState({
        title: "",
        description: "",
        rent: "",
    });
    const [newLead, setNewLead] = useState({
        name: "",
        email: "",
        notes: "",
    });
    const [message, setMessage] = useState({ text: "", type: "" });
    const [properties, setProperties] = useState([]);

    const fetchProperties = async () => {
        const response = await api.get("/properties");
        setProperties(response.data);
    };

    const [leads, setLeads] = useState([]);

    const fetchLeads = async (property_id) => {
        const response = await api.get(
            `/leads/${property_id ?? showLeadsForPropertyID}`
        );
        setLeads(response.data);
    };

    useEffect(() => {
        fetchProperties();
    }, []);

    const closeMessage = () => {
        setMessage({ text: "", type: "" });
    };

    if (message.text) {
        setTimeout(closeMessage, 3000);
    }

    // just refresh page on logout
    const onLogout = () => {
        window.location.reload();
    };

    const currentPropertyData = properties.find(
        (property) => property.id === showLeadsForPropertyID
    );

    return (
        <>
            {showLeadsForPropertyID ? (
                <span>
                    All Leads for: {}
                    {currentPropertyData?.title}
                    <br />
                    {currentPropertyData?.description}
                </span>
            ) : (
                <>
                    <span>Welcome, {username}!</span>
                </>
            )}
            <div className="properties-container">
                <div className="header">
                    <button
                        onClick={() =>
                            showLeadsForPropertyID
                                ? setShowAddLeadForm(true)
                                : setShowAddForm(true)
                        }
                        className="button"
                    >
                        Add {showLeadsForPropertyID ? "Lead" : "Property"}
                    </button>
                    {showLeadsForPropertyID ? (
                        <>
                            <button
                                onClick={() => {
                                    setShowLeadsForPropertyID(null);
                                    setShowAddLeadForm(false);
                                }}
                                className="button"
                            >
                                Back to Properties
                            </button>
                        </>
                    ) : (
                        <button onClick={onLogout} className="button">
                            Logout
                        </button>
                    )}
                </div>

                {message.text && (
                    <div
                        className={
                            message.type === "error"
                                ? "message-error"
                                : "message-success"
                        }
                    >
                        {message.text}
                    </div>
                )}

                {showAddLeadForm && (
                    <AddLead
                        setNewLead={setNewLead}
                        newLead={newLead}
                        showLeadsForPropertyID={showLeadsForPropertyID}
                        setShowAddLeadForm={setShowAddLeadForm}
                        setMessage={setMessage}
                        fetchLeads={fetchLeads}
                    />
                )}

                {showAddForm && (
                    <AddProperty
                        setNewProperty={setNewProperty}
                        newProperty={newProperty}
                        userId={userId}
                        setShowAddForm={setShowAddForm}
                        setMessage={setMessage}
                        fetchProperties={fetchProperties}
                    />
                )}

                {showLeadsForPropertyID && (
                    <LeadsList
                        leads={leads}
                        setMessage={setMessage}
                        setNewLead={setNewLead}
                        showLeadsForPropertyID={showLeadsForPropertyID}
                        fetchLeads={fetchLeads}
                    />
                )}

                {!showLeadsForPropertyID && (
                    <PropertiesList
                        properties={properties}
                        setMessage={setMessage}
                        setShowLeadsForPropertyID={setShowLeadsForPropertyID}
                        fetchProperties={fetchProperties}
                        setNewProperty={setNewProperty}
                        setShowAddForm={setShowAddForm}
                        fetchLeads={fetchLeads}
                    />
                )}
            </div>
        </>
    );
};

export default Properties;
