import axios from "axios";

const token = localStorage.getItem("token"); // Get token from localStorage

// Create an Axios instance with default configurations
const api = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}/api`, // Adjust this URL to your backend's URL
    headers: {
        Authorization: `Bearer ${token}`,
    },
});

export default api;
