// Login.js
import React, { useState } from "react";
import axios from "axios";

function Login({
  setToken,
  setUsername,
  toggleForms,
  setMessage,
  message,
  setUserId,
}) {
  const [userInput, setUserInput] = useState({ username: "", password: "" });

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/auth/login`,
        {
          username: userInput.username,
          password: userInput.password,
        }
      );
      setToken(response.data.token);
      setUsername(userInput.username);
      setUserId(response.data.userId);
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("username", userInput.username);
      localStorage.setItem("userId", response.data.userId);
    } catch (error) {
      console.log("Login failed: ", error);
    }
  };

  return (
    <div className="form-container">
      <form onSubmit={handleSubmit}>
        <h2>Login</h2>
        {message.text && (
          <div
            className={
              message.type === "error" ? "message-error" : "message-success"
            }
          >
            {message.text}
          </div>
        )}
        <div className="form-group">
          <label>Username:</label>
          <input
            type="text"
            value={userInput.username}
            onChange={(e) =>
              setUserInput({ ...userInput, username: e.target.value })
            }
          />
        </div>
        <div className="form-group">
          <label>Password:</label>
          <input
            type="password"
            value={userInput.password}
            onChange={(e) =>
              setUserInput({ ...userInput, password: e.target.value })
            }
          />
        </div>
        <div className="form-group">
          <button type="submit">Login</button>
        </div>
        <p className="toggle-form" onClick={toggleForms}>
          Need an account? Register
        </p>
      </form>
    </div>
  );
}

export default Login;
