// Register.js
import React, { useState } from "react";
import axios from "axios";

function Register({ toggleForms, setMessage, message }) {
  const [userInput, setUserInput] = useState({ username: "", password: "" });

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/register`, {
        username: userInput.username,
        password: userInput.password,
      });
      setMessage({
        text: "User registered successfully! You can now login.",
        type: "success",
      });
      toggleForms(); // Switch to login after registering
    } catch (error) {
      setMessage({
        text: "Failed to register. Please try again.",
        type: "error",
      });
    }
  };

  return (
    <div className="form-container">
      <form onSubmit={handleSubmit}>
        <h2>Register</h2>
        {message.text && (
          <div
            className={
              message.type === "error" ? "message-error" : "message-success"
            }
          >
            {message.text}
          </div>
        )}
        <div className="form-group">
          <label>Username:</label>
          <input
            type="text"
            value={userInput.username}
            onChange={(e) =>
              setUserInput({ ...userInput, username: e.target.value })
            }
          />
        </div>
        <div className="form-group">
          <label>Password:</label>
          <input
            type="password"
            value={userInput.password}
            onChange={(e) =>
              setUserInput({ ...userInput, password: e.target.value })
            }
          />
        </div>
        <div className="form-group">
          <button type="submit">Register</button>
        </div>
        <p className="toggle-form" onClick={toggleForms}>
          Already have an account? Login
        </p>
      </form>
    </div>
  );
}

export default Register;
