import React from "react";
import api from "../api"; // Import the configured Axios instance

const AddLead = ({
    setShowAddLeadForm,
    showLeadsForPropertyID,
    setMessage,
    fetchLeads,
    newLead,
    setNewLead,
}) => {
    const handleAddLead = async () => {
        try {
            await api.post("/leads", {
                ...newLead,
                property_id: showLeadsForPropertyID,
            });
            setShowAddLeadForm(false);
            fetchLeads();
            setMessage({
                text: "Lead added successfully!",
                type: "success",
            });
            setNewLead({
                name: "",
                email: "",
                notes: "",
            });
            return true;
        } catch (error) {
            setMessage({ text: "Failed to add lead.", type: "error" });
        }
    };

    return (
        <div className="addForm">
            <input
                type="text"
                placeholder="Name"
                value={newLead.name}
                onChange={(e) =>
                    setNewLead({
                        ...newLead,
                        name: e.target.value,
                    })
                }
            />
            <input
                type="text"
                placeholder="Email"
                value={newLead.email}
                onChange={(e) =>
                    setNewLead({
                        ...newLead,
                        email: e.target.value,
                    })
                }
            />
            <input
                type="textarea"
                placeholder="Notes"
                value={newLead.notes}
                onChange={(e) =>
                    setNewLead({
                        ...newLead,
                        notes: e.target.value,
                    })
                }
            />
            <button onClick={() => handleAddLead()}>Submit</button>
            <button
                onClick={() => {
                    setShowAddLeadForm(false);
                    setNewLead({
                        name: "",
                        email: "",
                        notes: "",
                    });
                }}
            >
                Cancel
            </button>
        </div>
    );
};

export default AddLead;
