import React, { useState } from "react";
import api from "../api"; // Import the configured Axios instance

const PropertiesList = ({
    fetchProperties,
    properties,
    setMessage,
    setShowLeadsForPropertyID,
    setNewProperty,
    setShowAddForm,
    fetchLeads,
}) => {
    const [showEditForm, setShowEditForm] = useState(null);

    const [editProperty, setEditProperty] = useState({
        title: "",
        description: "",
        rent: "",
    });

    const handleDeleteProperty = async (id) => {
        try {
            await api.delete(`/properties/${id}`);
            fetchProperties();
            setMessage({
                text: "Property deleted successfully!",
                type: "success",
            });
            setNewProperty({
                title: "",
                description: "",
                rent: "",
            });
        } catch (error) {
            setMessage({ text: "Failed to delete property.", type: "error" });
        }
    };

    const handleEditProperty = async (id) => {
        try {
            await api.put(`/properties/${id}`, {
                title: editProperty.title,
                description: editProperty.description,
                rent: editProperty.rent,
            });

            setShowEditForm(false);
            fetchProperties();
            setEditProperty({
                title: "",
                description: "",
                rent: "",
            });
            setMessage({
                text: "Property updated successfully!",
                type: "success",
            });
        } catch (error) {
            setMessage({ text: "Failed to update property.", type: "error" });
        }
    };

    return (
        <>
            {properties?.length === 0 ? (
                <p>No properties available. Please add some.</p>
            ) : (
                properties?.map((property) => (
                    <div key={property.id} className="property">
                        <div className="propertyWrapper">
                            <div className="dataInput">
                                {showEditForm === property.id ? (
                                    <div className="editForm">
                                        <div className="formInput">
                                            <label>Title:</label>
                                            <input
                                                type="text"
                                                value={editProperty.title}
                                                onChange={(e) =>
                                                    setEditProperty({
                                                        ...editProperty,
                                                        title: e.target.value,
                                                    })
                                                }
                                            />
                                        </div>

                                        <div className="formInput">
                                            <label>Info:</label>
                                            <input
                                                type="text"
                                                value={editProperty.description}
                                                onChange={(e) =>
                                                    setEditProperty({
                                                        ...editProperty,
                                                        description:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                        </div>

                                        <div className="formInput">
                                            <label>Rent:</label>
                                            <input
                                                type="number"
                                                value={editProperty.rent}
                                                onChange={(e) =>
                                                    setEditProperty({
                                                        ...editProperty,
                                                        rent: e.target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                        <button
                                            onClick={() =>
                                                handleEditProperty(property.id)
                                            }
                                        >
                                            Save
                                        </button>
                                        <button
                                            onClick={() => {
                                                setShowEditForm(null);

                                                setNewProperty({
                                                    title: "",
                                                    description: "",
                                                    rent: "",
                                                });
                                            }}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                ) : (
                                    <>
                                        <h3>{property.title}</h3>
                                        <p>{property.description}</p>
                                        <p>Rent: ${property.rent}</p>
                                    </>
                                )}
                            </div>
                            <div className="actionButtons">
                                <button
                                    onClick={async () => {
                                        setShowLeadsForPropertyID(property.id);
                                        setShowAddForm(false);
                                        fetchLeads(property.id);
                                    }}
                                >
                                    Show Leads
                                </button>
                                <button
                                    onClick={() =>
                                        handleDeleteProperty(property.id)
                                    }
                                >
                                    Delete
                                </button>
                                <button
                                    onClick={() => {
                                        setShowEditForm(property.id);
                                        setEditProperty({
                                            title: property.title,
                                            description: property.description,
                                            rent: property.rent,
                                        });
                                    }}
                                >
                                    Edit
                                </button>
                            </div>
                        </div>
                    </div>
                ))
            )}
        </>
    );
};

export default PropertiesList;
