import React from "react";
import api from "../api"; // Import the configured Axios instance

const PropertiesList = ({
    userId,
    setShowAddForm,
    setMessage,
    fetchProperties,
    newProperty,
    setNewProperty,
}) => {
    const handleAddProperty = async () => {
        try {
            const propertyData = { ...newProperty, userId: userId }; // Include userId
            await api.post("/properties", propertyData);
            setShowAddForm(false);
            fetchProperties();
            setMessage({
                text: "Property added successfully!",
                type: "success",
            });
            setNewProperty({
                title: "",
                description: "",
                rent: "",
            });
        } catch (error) {
            setMessage({ text: "Failed to add property.", type: "error" });
        }
    };

    return (
        <div className="addForm">
            <input
                type="text"
                placeholder="Title"
                value={newProperty.title}
                onChange={(e) =>
                    setNewProperty({
                        ...newProperty,
                        title: e.target.value,
                    })
                }
            />
            <input
                type="text"
                placeholder="Description"
                value={newProperty.description}
                onChange={(e) =>
                    setNewProperty({
                        ...newProperty,
                        description: e.target.value,
                    })
                }
            />
            <input
                type="number"
                placeholder="Rent"
                value={newProperty.rent}
                onChange={(e) =>
                    setNewProperty({
                        ...newProperty,
                        rent: e.target.value,
                    })
                }
            />
            <button onClick={handleAddProperty}>Submit</button>
            <button
                onClick={() => {
                    setShowAddForm(false);
                    setNewProperty({
                        title: "",
                        description: "",
                        rent: "",
                    });
                }}
            >
                Cancel
            </button>
        </div>
    );
};

export default PropertiesList;
